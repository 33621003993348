import {
	createRouter,
	createWebHashHistory,
	createWebHistory,
	RouteRecordRaw
} from "vue-router";
import CoverAI from "../views/CoverAI.vue";
import FastStats from "../views/FastStats.vue";
import TermsOfService from "../views/TermsOfService.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";
import CookiePolicy from "../views/CookiePolicy.vue";
import ThankYouPro from "../views/ThankYouPro.vue";
import API from "../views/API.vue";
import APILanding from "../views/APILanding.vue";
import Infinite from "../views/Infinite.vue";
import Login from "../views/Login.vue";

const routes: Array<RouteRecordRaw> = [
	{
		path: "/",
		name: "home",
		component: CoverAI
	},
	{
		path: "/art-ai",
		name: "Cover AI",
		component: CoverAI
	},
	{
		path: "/infinite",
		name: "Infinite",
		component: Infinite
	},
	{
		path: "/fast-stats",
		name: "FastStats",
		component: FastStats
	},
	{
		path: "/terms-of-service",
		name: "TermsOfService",
		component: TermsOfService
	},
	{
		path: "/privacy-policy",
		name: "PrivacyPolicy",
		component: PrivacyPolicy
	},
	{
		path: "/cookie-policy",
		name: "CookiePolicy",
		component: CookiePolicy
	},
	{
		path: "/thank-you-pro",
		name: "ThankYouPro",
		component: ThankYouPro
	},
	{
		path: "/thank-you-pro-plus",
		name: "ThankYouProPlus",
		component: ThankYouPro
	},
	{
		path: "/api",
		name: "API",
		component: API
	},
	{
		path: "/api-landing",
		name: "APILanding",
		component: APILanding
	},
	{
		path: "/login",
		name: "Login",
		component: Login
	}
];

const createHistory =
	typeof process.env.VUE_APP_USE_HTML5_HISTORY === "string"
		? createWebHistory
		: createWebHashHistory;

const router = createRouter({
	history: createHistory(),
	routes
});

export default router;
