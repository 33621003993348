import { ref, watch, computed } from "vue";
import { Magic } from "magic-sdk";
import { config } from "@/config";
import { ensureThreads } from "@/lib/ai/call-sd";

const magic = new Magic("pk_live_4814BF57599EA872");

// @ts-ignore
window.magic = magic;

type Account = {
	email: string;
	key: string;
	plan: string;
};

const account = ref<Account | undefined>();

export const isLoggedIn = computed(() => account.value !== undefined);

{
	const cachedAccount = localStorage.getItem("accountv2");

	if (cachedAccount !== null) {
		account.value = JSON.parse(cachedAccount);
	}
}

if (location.hostname === "localhost") {
	account.value = {
		email: "test@prodia.com",
		key: "xxx",
		plan: "free"
	};
}

export const logout = () => {
	account.value = undefined;
	localStorage.removeItem("accountv2");
	magic.user.logout();
};

export const email = computed(() => account.value?.email);
export const key = computed(() => account.value?.key);
export const plan = computed(() => account.value?.plan);
export const credits = computed(() => {
	if (plan.value === "pro") {
		return 2000;
	}
	if (plan.value === "super") {
		return 8000;
	}
	// assume free tier credits
	return 1000;
});

export const isPaid = computed(
	() => plan.value === "pro" || plan.value === "super"
);

export const handlePlan = () => {
	if (plan.value === "pro") {
		ensureThreads(2);
	}

	if (plan.value === "super") {
		ensureThreads(4);
	}
};

handlePlan();

export const displayPlan = computed(() => {
	const plans = {
		free: "Free",
		pro: "Pro ✨",
		super: "Pro+ ⚡️"
	};

	if (plan.value === undefined) return;

	if (plan.value in plans) {
		// @ts-ignore
		return plans[plan.value];
	}
});

export const showAccount = ref<boolean>(true);

export const showLoginModal = ref<boolean>(false);
export const showLogoutModal = ref<boolean>(false);

const completeLogin = async () => {
	const metadata = await magic.user.getMetadata();

	if (typeof metadata.email !== "string" || metadata.email.length < 1) {
		throw new Error("Bad email");
	}

	const response = await fetch(`${config.arranUrl}/key`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json"
		},
		body: JSON.stringify({
			token: await magic.user.getIdToken()
		})
	});

	// normalize email
	const email = metadata.email.trim().toLowerCase();

	const { key, plan } = await response.json();

	if (plan === "pro" || plan === "super") {
		localStorage.setItem(
			"accountv2",
			JSON.stringify({
				email,
				key,
				plan
			} as typeof account.value)
		);
	}

	account.value = { email, key, plan };

	handlePlan();
};

export const login = async (userEmail: string) => {
	try {
		await magic.auth.loginWithMagicLink({ email: userEmail });
	} catch (err) {}

	await completeLogin();
};

export const loginCheck = (async () => {
	if (account.value === undefined) {
		showAccount.value = false;
	}

	if (await magic.user.isLoggedIn()) {
		await completeLogin();
	}

	showAccount.value = true;
})();
